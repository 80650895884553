import { Box } from "@mui/material";
import dynamic from "next/dynamic";

const LottieAnimation = dynamic(() => import("../share/LottieAnimation"), {
  ssr: false,
});
export const PageLoader: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: loadingAnimationData,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        width={300}
        height={300}
      /> */}
      <LottieAnimation />
    </Box>
  );
};
