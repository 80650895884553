1;

const config = {
  hospitadApiUrl: process.env.hospitadApiUrl,
  internalApi: process.env.NEXT_PUBLIC_internalApi,
  appEnv: process.env.NEXT_PUBLIC_appEnv,
  gtagTrackingId: process.env.NEXT_PUBLIC_gtagTrackingId,
  sentryDsn: process.env.NEXT_PUBLIC_sentry_dsn,
  tawktoPropertyId: process.env.NEXT_PUBLIC_TAWKTO_PROPERTY_ID,
  tawktoWidgetId: process.env.NEXT_PUBLIC_TAWKTO_WIDGET_ID,
  tawktoWidgetIdNew: process.env.NEXT_PUBLIC_TAWKTO_WIDGET_ID_NEW,
  launchDarklyClientId: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
  heapId: process.env.NEXT_PUBLIC_HEAP_ID,
  hotjarId: process.env.NEXT_PUBLIC_HOTJAR_ID,
  survicateId: process.env.NEXT_PUBLIC_SURVICATE_ID,
  STRIPE_SECRET_KEY: process.env.STRIPE_SECRET_KEY,
  STRIPE_WEBHOOKS_SECRET: process.env.STRIPE_WEBHOOKS_SECRET,
  GOOGLE_CAPTCHA: process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA,
};
const stripeConfiguration = {
  paths: {
    api: {
      checkout: "/api/stripe/checkout",
      billingPortal: "/api/stripe/portal",
    },
  },
};
export const serviceRageDate = {
  longStartDate: new Date(946684800000).toISOString().split("T")[0],
  startDate: new Date(1652828400000).toISOString().split("T")[0],
  endDate: new Date().toISOString().split("T")[0],
};
export { stripeConfiguration };

export default config;
